import { ɵgetAllInstancesOf, ɵisSupportedError, ɵgetDefaultInstanceOf, VERSION, ɵAngularFireSchedulers, ɵzoneWrap } from '@angular/fire';
import { from, timer } from 'rxjs';
import { concatMap, distinct } from 'rxjs/operators';
import * as i0 from '@angular/core';
import { InjectionToken, Optional, APP_INITIALIZER, NgModule, NgZone, Injector, makeEnvironmentProviders } from '@angular/core';
import { FirebaseApp, FirebaseApps } from '@angular/fire/app';
import { registerVersion } from 'firebase/app';
import { isSupported as isSupported$1, deleteToken as deleteToken$1, getMessaging as getMessaging$1, getToken as getToken$1, onMessage as onMessage$1 } from 'firebase/messaging';
export * from 'firebase/messaging';
class Messaging {
  constructor(messaging) {
    return messaging;
  }
}
const MESSAGING_PROVIDER_NAME = 'messaging';
class MessagingInstances {
  constructor() {
    return ɵgetAllInstancesOf(MESSAGING_PROVIDER_NAME);
  }
}
const messagingInstance$ = timer(0, 300).pipe(concatMap(() => from(ɵgetAllInstancesOf(MESSAGING_PROVIDER_NAME))), distinct());
const isMessagingSupportedPromiseSymbol = '__angularfire_symbol__messagingIsSupported';
const isMessagingSupportedValueSymbol = '__angularfire_symbol__messagingIsSupportedValue';
globalThis[isMessagingSupportedPromiseSymbol] ||= isSupported$1().then(it => globalThis[isMessagingSupportedValueSymbol] = it).catch(() => globalThis[isMessagingSupportedValueSymbol] = false);
const isMessagingSupportedFactory = {
  async: () => globalThis[isMessagingSupportedPromiseSymbol],
  sync: () => {
    const ret = globalThis[isMessagingSupportedValueSymbol];
    if (ret === undefined) {
      throw new Error(ɵisSupportedError('Messaging'));
    }
    return ret;
  }
};
const PROVIDED_MESSAGING_INSTANCES = new InjectionToken('angularfire2.messaging-instances');
function defaultMessagingInstanceFactory(provided, defaultApp) {
  if (!isMessagingSupportedFactory.sync()) {
    return null;
  }
  const defaultMessaging = ɵgetDefaultInstanceOf(MESSAGING_PROVIDER_NAME, provided, defaultApp);
  return defaultMessaging && new Messaging(defaultMessaging);
}
function messagingInstanceFactory(fn) {
  return (zone, injector) => {
    if (!isMessagingSupportedFactory.sync()) {
      return null;
    }
    const messaging = zone.runOutsideAngular(() => fn(injector));
    return new Messaging(messaging);
  };
}
const MESSAGING_INSTANCES_PROVIDER = {
  provide: MessagingInstances,
  deps: [[new Optional(), PROVIDED_MESSAGING_INSTANCES]]
};
const DEFAULT_MESSAGING_INSTANCE_PROVIDER = {
  provide: Messaging,
  useFactory: defaultMessagingInstanceFactory,
  deps: [[new Optional(), PROVIDED_MESSAGING_INSTANCES], FirebaseApp]
};
class MessagingModule {
  constructor() {
    registerVersion('angularfire', VERSION.full, 'fcm');
  }
  static ɵfac = function MessagingModule_Factory(t) {
    return new (t || MessagingModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: MessagingModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [DEFAULT_MESSAGING_INSTANCE_PROVIDER, MESSAGING_INSTANCES_PROVIDER, {
      provide: APP_INITIALIZER,
      useValue: isMessagingSupportedFactory.async,
      multi: true
    }]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MessagingModule, [{
    type: NgModule,
    args: [{
      providers: [DEFAULT_MESSAGING_INSTANCE_PROVIDER, MESSAGING_INSTANCES_PROVIDER, {
        provide: APP_INITIALIZER,
        useValue: isMessagingSupportedFactory.async,
        multi: true
      }]
    }]
  }], () => [], null);
})();
function provideMessaging(fn, ...deps) {
  registerVersion('angularfire', VERSION.full, 'fcm');
  return makeEnvironmentProviders([DEFAULT_MESSAGING_INSTANCE_PROVIDER, MESSAGING_INSTANCES_PROVIDER, {
    provide: APP_INITIALIZER,
    useValue: isMessagingSupportedFactory.async,
    multi: true
  }, {
    provide: PROVIDED_MESSAGING_INSTANCES,
    useFactory: messagingInstanceFactory(fn),
    multi: true,
    deps: [NgZone, Injector, ɵAngularFireSchedulers, FirebaseApps, ...deps]
  }]);
}
const isSupported = isMessagingSupportedFactory.async;

// DO NOT MODIFY, this file is autogenerated by tools/build.ts
const deleteToken = ɵzoneWrap(deleteToken$1, true);
const getMessaging = ɵzoneWrap(getMessaging$1, true);
const getToken = ɵzoneWrap(getToken$1, true);
const onMessage = ɵzoneWrap(onMessage$1, false);

/**
 * Generated bundle index. Do not edit.
 */

export { Messaging, MessagingInstances, MessagingModule, deleteToken, getMessaging, getToken, isSupported, messagingInstance$, onMessage, provideMessaging };
